import {BASE_URL_PLACEHOLDER, ROUTES} from '@/config/constants'
import {FeatureConfig, FeatureToggle} from '@/config/featureToggles'
import {getCountries} from '@/helpers/envConfig'
import {isFeatureEnabled} from '@/helpers/featureToggle'
// import {oktaAccessToken, oktaAuth} from '@/helpers/oktaConfig'
import {store} from '@/store'
import axios, {AxiosError, HttpStatusCode} from 'axios'
import {z} from 'zod'

export enum HTTPMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
  MULTIPART_FORMDATA = 'multipart/form-data',
}

const onUnauthorisedError = async () => {
  // await oktaAuth.signOut()
}

export const handleNetworkErrors = async (error: AxiosError) => {
  const isCanceled = error.code === AxiosError.ERR_CANCELED

  if (isCanceled) return Promise.reject(error)

  const isUnauthorized = error.response?.status === HttpStatusCode.Unauthorized
  const isForbidden = error.response?.status === HttpStatusCode.Forbidden
  const isNetworkError = error.code === AxiosError.ERR_NETWORK

  if (isForbidden) {
    window.location.href = ROUTES.ERROR_403
  } else if (isUnauthorized || isNetworkError) {
    try {
      await onUnauthorisedError()
    } catch (signOutError) {
      console.error('onUnauthorisedError::ERROR', signOutError)
    }
    // window.location.reload()
  }

  return Promise.reject(error)
}

const axiosiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})
axiosiInstance.interceptors.request.use(
  config => config,
  error => handleNetworkErrors(error)
)
axiosiInstance.interceptors.response.use(
  response => response,
  error => handleNetworkErrors(error)
)

export default function api<Request, Response>({
  method = HTTPMethod.GET,
  contentType = ContentType.APPLICATION_JSON,
  path,
  requestSchema,
  responseSchema,
  signal,
  onUploadProgress,
}: {
  method?: HTTPMethod
  path: string
  requestSchema: z.ZodType<Request>
  responseSchema: z.ZodType<Response>
  signal?: AbortSignal
  contentType?: ContentType
  onUploadProgress?: (progressEvent: ProgressEvent) => void
}): (data: Request) => Promise<Response> {
  return (requestData: Request) => {
    try {
      requestSchema.parse(requestData)
    } catch (error) {
      console.error(`requestSchema.parse::ERROR [${method}] [${path}]`, (error as z.ZodError).message)
      throw error
    }

    async function apiCall() {
      const {url, baseURL, isAdminPortalUrl} = pathAdapter(path)

      const shouldSendCountryHeader = !isAdminPortalUrl && isFeatureEnabled(FeatureToggle.countryHeader)

      const response = await axiosiInstance.request({
        signal,
        baseURL,
        url,
        headers: {
          // Authorization: oktaAccessToken(),
          country: shouldSendCountryHeader ? store.getState().journeyConfig.country || getCountries()[0] : undefined,
          'Content-Type': contentType,
        },
        method,
        [method === HTTPMethod.GET ? 'params' : 'data']: requestData,

        onUploadProgress: progressEvent => onUploadProgress?.(progressEvent as unknown as ProgressEvent),
      })
      try {
        return responseSchema.parse(response.data)
      } catch (error) {
        console.error(`responseSchema.parse::ERROR [${method}] [${path}]`, (error as z.ZodError).message, response.data)
        throw error
      }
    }

    return apiCall()
  }
}

const pathAdapter = (path: string) => {
  let baseURL = import.meta.env[FeatureConfig.apiUrl]

  const adminPortalPlaceholder = BASE_URL_PLACEHOLDER.ADMIN_PORTAL
  const isAdminPortalUrl = path.includes(adminPortalPlaceholder)
  if (isAdminPortalUrl) {
    baseURL = import.meta.env[FeatureConfig.apiAdminUrl]
  }

  const realTimeEventsPlaceholder = BASE_URL_PLACEHOLDER.EVENTS
  const isRealTimeEventsUrl = path.includes(realTimeEventsPlaceholder)
  if (isRealTimeEventsUrl) {
    baseURL = import.meta.env[FeatureConfig.apiEventsUrl]
  }

  const url = path.replace(adminPortalPlaceholder, '').replace(realTimeEventsPlaceholder, '')

  return {
    url,
    baseURL,
    isAdminPortalUrl,
    isRealTimeEventsUrl,
  }
}
